import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Testimonials from "../components/testimonials"

export const seo = {
  title: "Crampete | Your own learning app",
  description:
    "Learn Full Stack Web Development, Data Science, Digital Marketing, and other in-demand tech skills via online, classroom and self-paced courses. It's time to up-skill!",
  keywords:
    "Full Stack developer online course, front end, back end, data science, digital marketing",
  image: "",
  url: "https://www.crampete.com",
  author: "Crampete",
}

class Home extends Component {

  

  render() {

    // console.log('URL', global.window);

    return (
      <Layout
        title={seo.title}
        description={seo.description}
        image={seo.image}
        keywords={seo.keywords}
        url={seo.url}
        author={seo.author}
      >
        <section className="homeBanner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row home-content">
                  <div className="col-lg-12 text-center">
                    <h1 className="banner-title">
                      LEARN THE SKILLS THAT MAKE YOU IN-DEMAND
                    </h1>
                    <p className="text-dark">
                      Enroll in classroom, live and self-paced tech courses
                      designed for industry requirements and to make you an
                      in-demand pro.
                    </p>
                    <Link
                      className="text-white btn btn-primary mt-4"
                      to="/cramclass"
                    >Explore Courses</Link>
                    <div className="ourStudents">
                      <h4>Our students work in</h4>
                      <img
                        src="./images/home-page_stripe-logo.png"
                        alt="Companies our students work | Crampete"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className="container hrLight" style={{ maxWidth: "600px" }} />
        <section id="top-courses" className="top-courses">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="main-title text-center">
                  <h3 className="mt0">Browse Our Top Programs</h3>
                  <p>
                    All our programs are designed based on the industry
                    requirements by our expert mentors.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  alt="Cram class"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramclass.jpg"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-6 m-auto">
                <h4 className="mt0">Cram Classes</h4>
                <p className="mt-4">
                  Offering live online classes and classroom courses on
                  in-demand tech skills. All our courses are designed to satisfy
                  industry expectations. They are delivered by tech
                  professionals with amazing track record.
                </p>
                <p className="mt-4">
                  Cram Class taps the power of group learning to up-skill you by
                  a specific date.
                </p>
                <p className="mt-4">
                  <Link className="btn btn-primary" to="/cramclass">
                    View More
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 order-sm-12">
                <img
                  alt="cram code"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramcode.jpg"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-sm-6 order-sm-1 m-auto">
                <h4 className="mt0">Cram Code</h4>
                <p className="mt-4">
                  Cram Code offers self-paced online courses on emerging tech
                  skills. These courses are designed to fulfil industry
                  expectations. They are delivered by tech professionals and can
                  be accessed anywhere, anytime.
                </p>
                <p className="mt-4">
                  Take your career in your hands and up-skill yourself at your
                  will.
                </p>
                <p className="mt-4">
                  <Link className="btn btn-primary" to="/cramcode">
                    View More
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  alt="cram hire"
                  src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/home/cramhire.jpg"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-6 m-auto">
                <h4 className="mt0">Cram Hire</h4>
                <p className="mt-4">
                  Hire pre-trained, pre-qualified candidates for a range of tech
                  roles. All our students have undergone extensive training,
                  completed numerous projects and possess a project portfolio
                  that demonstrates their skills.
                </p>
                <p className="mt-4">
                  Access our trained and certified candidates, ready to join
                  you!
                </p>
                <p className="mt-4">
                  <Link className="btn btn-primary" to="/cramcode">
                    View More
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="main-title py-5 row justify-content-center">
              <div className="col-md-8 text-center">
                <h3 className="mt0">A community in the making!</h3>
                <p>
                  Here's what our students have to say about being a part of the
                  Crampete family and learning skills that empower them! A
                  community in the making- sharing skills, experience and
                  knowledge!
                </p>
              </div>
            </div>
          </div>
          <Testimonials />
        </section>
        <section id="our-partners" className="our-partners ourLearners">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="main-title text-center">
                  <h3 className="mt0">Our learners come from</h3>
                  <p>
                    All our programs are designed based on the industry
                    requirements by out expert mentors.
                  </p>
                </div>
              </div>
            </div>
            <ul className="learnerImgMenu">
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Hinduja.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Iopex.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Lynk.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Sulekha.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/UST.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Ford.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Hexaware.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/HP.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/NTT.png"
                  alt=""
                />
              </li>
              <li>
                <img
                  className=" ls-is-cached lazyloaded"
                  src="../images/learnercompanies/Tata.png"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </section>
        <hr className="container hrLight" />
      </Layout>
    )
  }
}

export default Home
