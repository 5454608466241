import React from "react"
import Card from 'react-bootstrap/Card'

export default () => (
  <div className="container testimonialOuter">
    <div className="row">
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                  "Crampete has a great way of teaching – it's highly practical-oriented rather than a theoretical class. You've to sit and work out the problems each and every day. There are daily assignments and weekly homework. I personally recommend Crampete as a good way to start your career in data science."
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Rohan.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">Rohan Samuel Jayaseelan</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “I’ve taken up Crampete full stack developer course coming from zero coding and programming knowledge myself. It’s an intuitive ecosystem which makes total newbies easy to pick up and learn. Crampete also provides live discussing sessions for the learners to have live interactions with the lecturers which is an absolute plus point.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Barrathan.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">BARRATHAN, MALAYSIA</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “Full Stack Self Paced Course from Crampete is good for me even though I had zero background in programming. Now, I am able to build a website by myself and all that skills are from learning the course offered by Crampete. The best feature in this course is that Crampete offers mentoring sessions, so whenever I have problems, the mentors are ready to guide me in solving the problems.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Syafiqah-Aziza.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">SYAFIQAH AZIZA, MALAYSIA</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “Crampete's Full Stack Web Development is the program you need to take to become a great programmer. It teaches a lot of programming languages like HTML, CSS, JavaScript, MongoDB, DynamoDB, React JS, AJAX, and so on. I first learned front-end development, then back-end development, and then backend development. Tri Thanh Alan joined Konexaa as a Software Developer.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Alan.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">TRI THANH ALAN</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “I was having nil knowledge about programming before joining Crampete. The experienced trainers are friendly and approachable. They taught personally and individually – until we got the concepts clearly. The only thing that stood apart in my resume is the Crampete classes. I got placed in Hexaware and NTT Data!”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Joshiba.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">JOSHIBA FERNANDO</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “I learned Python, statistics, machine learning which is a valuable skillset for data scientists. Before joining Crampete I had zero knowledge in all the above. Crampete's well-trained staff got me up to speed through rigorous practice exercises. I highly recommend Crampete for learning Data Science.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/Pragrith.jpg" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">PRAGRITH</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “I think it is a good course because it covers everything that I need to know from front end to back end development. Plus, it was very helpful due to flexibility of time they have and a supportive mentor to guide along the way.The course definitely gives me a good overview of what full stack web developers do and provides the essentials that I need to land a job.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/placeholder.png" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">NUR AMIRAH AMINAH, MALAYSIA</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “The Full Stack Self Paced Course offered by Crampete is really comprehensive in a sense that it is really good for beginners and it builds up skills especially with the hands on lab sessions.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/placeholder.png" alt="wrapkit" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">FARAH NADIA, MALAYSIA</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “Crampete provided me a platform and gateway into programming which enabled me to learn in more intuitive ways even though I was from a different major in engineering. I learned MERN stack web development in-classroom training. The trainers and the support staffs are knowledgeable and friendly. I could able to show the portfolio of projects which I built during my training to my prospective employers. I could able to secure a job with D4t4 Solutions PLC as a Software Developer. I would recommend Crampete courses to anyone who has an interest in Computer programming or would like to start a career in Information Technology.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/arun.png" alt="arun" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">Arun Kumar R</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “A normal person wants the best but only some find the best so did i. I was in search of the best destination to learn and experience digital marketing. So in search of the best i ended up with the best and the name of the institution is "Crampete" ”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/tharun.jpg" alt="atharun" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">Tharun</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-xl-4 mb-4">
            <Card className="cardShadow h-100">
              <Card.Body>
                <Card.Text className="card-description">
                “Hi I'm currently learning web full-stack in crampete. It has provided me with a platform to learn and equip coding skills immensely. It helped me to enhance via assignments and interactions via classes. The class is very comfortable especially for working folks who want to update their technical skills to attend classes on weekends and the faculty members are flexible and clarify doubts instantly.”
                  <br /><br /><br />
                </Card.Text>
                <div className="d-block d-md-flex align-items-center">
                  <span className="thumb-img"><img src="https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/testimonials/akshaya.jpg" alt="akshaya" className="rounded-circle"/></span>
                  <div className="customer-block">
                    <h6 className="mb-0 customer">Akshaya</h6>
                  </div>
              </div>
              </Card.Body>
            </Card>
        </div>
      </div>
  </div>
)
